import React from "react"

const RadialStepper = ({ currentStep = 0, totalSteps = 0 }) => {
  const PROGRESS_OFFSET = 189
  const progressAnimation = `
    @keyframes radial-progress {
      100% {
        stroke-dashoffset: ${
          PROGRESS_OFFSET -
          Math.floor((PROGRESS_OFFSET * currentStep) / totalSteps)
        };
      }
    }
  `
  return (
    <div className="radial-stepper is-flex">
      <style>{progressAnimation}</style>
      <svg className="radial" height="68" width="68">
        <circle
          cx="34"
          cy="34"
          r="30"
          stroke="#f5f5f5"
          strokeWidth="7"
          fill="none"
        />
        <circle
          style={{
            animation: "radial-progress 1.5s forwards",
          }}
          cx="34"
          cy="34"
          r="30"
          transform="rotate(-90 34 34)"
          stroke="#ed7322"
          strokeWidth="7"
          strokeDasharray={PROGRESS_OFFSET}
          strokeDashoffset={PROGRESS_OFFSET}
          fill="none"
        />
      </svg>
      <div className="radial-step is-size-6 has-text-centered">
        {currentStep} of {totalSteps}
      </div>
    </div>
  )
}

export default RadialStepper
